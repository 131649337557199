// Polyfill
import 'core-js/stable'
import 'regenerator-runtime/runtime'

// Libraries
import L from 'leaflet'
window.L = L
import 'leaflet-draw'
import 'leaflet-editable'

// Translations
L.drawLocal.draw.handlers.polyline = {
	error: '<strong>Fout:</strong> vormranden kunnen niet kruisen!',
	tooltip: {
		start: 'Klik om te beginnen met het tekenen van een lijn.',
		cont: 'Klik om door te gaan met het tekenen van de lijn.',
		end: 'Klik laatst getekende punt om deze lijn te voltooien.',
	},
}

// Styles
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import 'mapbox-gl/dist/mapbox-gl.css'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'
import { theme } from './theme'
import { ThemeProvider as MuiThemeProvider, CssBaseline, StylesProvider } from '@material-ui/core'
import { createRootStore } from './store/RootStore'
import { Provider } from 'mobx-react'
import { ThemeProvider } from 'styled-components'
import ErrorBoundary from '~components/ErrorBoundary'
import axios from 'axios'

const store = createRootStore()

axios.defaults.withCredentials = true

ReactDOM.render(
	<Provider value={store}>
		<ThemeProvider theme={theme}>
			<MuiThemeProvider theme={theme}>
				<StylesProvider injectFirst>
					<CssBaseline />
					<ErrorBoundary>
						<App />
					</ErrorBoundary>
				</StylesProvider>
			</MuiThemeProvider>
		</ThemeProvider>
	</Provider>,
	document.getElementById('app')
)
