import { GridProps, Grid, FormControl, InputLabel, Input, InputAdornment, ButtonGroup } from '@material-ui/core'
import { observer } from 'mobx-react'
import * as React from 'react'
import Button from '~components/buttons/Button'
import MapEditorModal from './MapEditorModal'
import { useFormRecord } from '~components/model-form/ModelFormContext'
import { AnyApiModel } from '~api/model/ApiModel'
import { Map } from '~store/models/Map'
import styled from 'styled-components'
import { Notification } from '~store/models/Notification'
import Icon from '~components/gui/Icon'
import useStore from '~store/hooks/useStore'
import copy from 'clipboard-copy'
import { GuiStoreNotificationSeverity } from '~store/stores/GuiStore'
import { draft, SnapshotOutOf, getSnapshot, fromSnapshot } from 'mobx-keystone'
import { Journey } from '~store/models/Journey'
import { KeolisColors } from '../../theme'
import Select from '~components/select/Select'
import { MapData } from '~map-element/index'

const ImageContainer = styled.div`
	background: #00000011;

	border: 10px solid #333;
	display: flex;
	justify-content: center;

	overflow: hidden;

	cursor: pointer;
	position: relative;
	&:hover {
		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background: #000;
			opacity: 0.2;
		}
	}
`

const Container = styled.div`
	display: flex;
	flex-direction: row;

	&.horizontal {
		${ImageContainer} {
			flex-shrink: 0;
			width: 200px;
			height: 200px;
		}
		> :last-child {
			padding: 12px 0;
		}
		justify-content: stretch;
	}

	&.vertical {
		flex-direction: column;
		${ImageContainer} {
			width: 100%;
			height: 320px;
			img {
				width: 100%;
			}
		}
	}
`

const Img = styled.img`
	display: block;
`

const Image = styled.div`
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 100% auto;
`

const Label = styled(InputLabel)`
	margin-bottom: 8px;
	font-weight: bold;
	font-size: 14px;
	color: ${props => props.theme.palette.secondary.main};
	i.fa {
		margin-right: 0.5em;
	}
`

const NoMapYet = styled.div`
	align-self: center;
	font-size: 24px;
	opacity: 0.1;
	font-weight: bold;
`

const SelectExisting = styled.div`
	padding: 0 10px 10px;
	background: #333;
`

const MapLink = styled.div`
	display: flex;
	flex-direction: row-reverse;

	.fa {
		font-size: 20px;
		margin: 0.25em 0.5em 0.5em;
	}

	background: #333;
	padding: 0 10px 10px;
	font-size: 12px;
	font-family: 'Courier New';
	> * {
		opacity: 0.5;
	}
	&:hover {
		cursor: pointer;
		.fa {
			color: ${props => props.theme.palette.primary.main};
		}
		> * {
			opacity: 1;
		}
	}
`

const Buttons = styled.div`
	display: flex;
	background: #333;
	padding: 5px 8px 10px;

	> * {
		font-size: 12px;
		margin: 0 2px;
	}
`

export interface IMapFieldProps {
	record?: AnyApiModel
	notification?: Notification
	attribute?: string
	copyToAttribute?: string
	label?: string
	grid?: GridProps
	mapType?: 'driver' | 'public'
	horizontal?: boolean
	onOpen?: (map: Map) => any
	onDelete?: (map: Map) => any
	existingMaps?: Map[]
}

const MapField = (props: IMapFieldProps) => {
	const {
		mapType = 'driver',
		attribute,
		notification,
		horizontal,
		onOpen,
		onDelete,
		label,
		grid = { xs: 12 },
		copyToAttribute,
		existingMaps,
	} = props
	const { gui, api } = useStore()

	// State
	const [isOpen, setIsOpen] = React.useState<boolean>(false)
	const [isLoading, setIsLoading] = React.useState<boolean>(false)
	const [isCopying, setIsCopying] = React.useState<boolean>(false)

	// Get record
	const formRecord = useFormRecord()
	const record: AnyApiModel = props.record || formRecord!

	// Form map
	const [map, setMap] = React.useState<Map | null>(null)
	const recordAttr = attribute ? record[attribute] : record
	React.useEffect(() => {
		setMap(recordAttr || null)
	}, [recordAttr])

	// Select options existing map
	const mapOptions = React.useMemo(() => {
		if (!existingMaps || existingMaps.length === 0) return null
		return existingMaps.map(map => ({
			label: map.title,
			map,
		}))
	}, [existingMaps])

	const handleSelectExistingMap = React.useCallback(
		option => {
			const map = option.map as Map
			if (attribute) record.setAttribute(attribute, map)
			setMap(map)
		},
		[record, attribute]
	)

	// Open
	const openEditor = React.useCallback(async () => {
		// Manual open?
		if (onOpen && map) {
			onOpen(map)
			return
		}

		// Do we have a map yet?
		setIsLoading(true)
		if (!map) {
			// Create new map
			const newMap = new Map({})
			setMap(newMap)

			// Guess some layers from KV15
			if (notification && notification.source_data) {
				// A single line?
				const data = notification.source_data
				const lineNumber =
					data.linePlanningNumbers && data.linePlanningNumbers.length >= 1 ? data.linePlanningNumbers[0] : null
				if (lineNumber) {
					// Get the journeys
					const result = await api.get('/journeys', {
						filters: {
							line_number: lineNumber,
							is_primary: true,
							'lineVersion.is_current_version': true,
						},
						include: ['lineVersion.line'],
						castAs: Journey,
					})

					// Add journeys
					if (result.records.length > 0) {
						// Load steps
						const journeys: Journey[] = result.records
						const promises = journeys.map(j => j.loadSteps(api))
						await Promise.all(promises)

						// Add to map
						await newMap.addJourneyLayers(journeys[0].lineVersion!.line!, journeys, KeolisColors[0])
					}

					// Any cancelled stops?
					if (data.subEffectType === '5__1' && data.userStopCodes) {
						// Go through cancelled stops
						const stopLayers = newMap.mapData.getAllStopLayers()
						data.userStopCodes.forEach((code: string) => {
							// Find stops
							const stopLayer = stopLayers.find(l => l.stopCode === code)
							if (stopLayer) stopLayer.setProperty('isCancelled', true)
						})
					}
				}
			}
		}

		// Open
		setIsLoading(false)
		setIsOpen(true)
	}, [record, attribute, notification, map, onOpen])

	// Save
	const onSave = React.useCallback(
		(map: Map) => {
			// Set it
			if (attribute) record.setAttribute(attribute, map)

			// Close
			setIsOpen(false)
		},
		[attribute, record]
	)

	// Store original data
	const mapData: MapData | null = map && map.mapData
	const [originalData, setOriginalData] = React.useState<SnapshotOutOf<MapData> | null>(null)
	React.useEffect(() => {
		setOriginalData(mapData ? getSnapshot(mapData) : null)
	}, [mapData, setOriginalData])

	// Close map
	const onClose = React.useCallback(() => {
		// Revert
		if (map) map.setAttribute('mapData', fromSnapshot(originalData))

		setIsOpen(false)
	}, [setIsOpen, originalData, map])

	// Image
	const image = map && map.images && map.images.length > 0 ? map.images[0] : null

	// Copy to other attribute
	const handleCopyMap = React.useCallback(async () => {
		// State
		if (!map || !copyToAttribute) return
		setIsCopying(true)

		// Save it.
		const copy = map.copy()
		const mapDraft = draft(copy)
		await api.saveModel(mapDraft, {
			onlyDirty: false,
			omitAttributes: ['mapData'],
			applyAttributesFromServer: ['id', 'public_token'],
		})

		// Set it
		record.setAttribute(copyToAttribute, copy)

		// Done.
		setIsCopying(false)
	}, [api, record, map, copyToAttribute])

	// Delete map
	const handleDelete = React.useCallback(() => {
		// Manual?
		if (onDelete && map) {
			onDelete(map)
			return
		}

		if (attribute) record.setAttribute(attribute, null)
	}, [record, attribute, onDelete, map])

	// Link
	const handleMapLinkClick = React.useCallback(() => {
		if (!map || !map.iframeCode) return
		copy(map.iframeCode)
		gui.showNotification({
			severity: GuiStoreNotificationSeverity.success,
			message: 'De iframe-code is gekopieerd naar het klembord.',
		})
	}, [map, gui])

	return (
		<Grid item {...grid}>
			{label && (
				<Label>
					<Icon name={mapType === 'driver' ? 'bus' : 'globe'} />
					{label}
				</Label>
			)}

			<Container className={horizontal ? 'horizontal' : 'vertical'}>
				<ImageContainer onClick={openEditor}>
					{image ? <Image style={{ backgroundImage: `url(${image.base64}` }} /> : <NoMapYet>Nog geen kaart</NoMapYet>}
				</ImageContainer>

				{/* Link */}
				{map && map.iframeCode && (
					<div>
						<MapLink onClick={handleMapLinkClick}>
							<Icon name={'copy'} />
							<span>{map.iframeCode}</span>
						</MapLink>
						<Buttons>
							{copyToAttribute && (
								<Button
									onClick={handleCopyMap}
									loading={isCopying}
									mini
									color={'primary'}
									variant={'outlined'}
									startIcon={<Icon name={'copy'} />}
									endIcon={<Icon name={mapType === 'driver' ? 'arrow-right' : 'arrow-left'} />}>
									Kopieren voor {mapType === 'driver' ? 'reiziger' : 'chauffeur'}
								</Button>
							)}
							<Button onClick={handleDelete} mini danger variant={'outlined'} startIcon={<Icon name={'trash'} />}>
								Verwijderen
							</Button>
						</Buttons>
					</div>
				)}

				{/* Select existing */}
				{!map && existingMaps && existingMaps.length > 0 && (
					<SelectExisting>
						<Select options={mapOptions} onChange={handleSelectExistingMap} placeholder={'Selecteer bestaande kaart'} />
					</SelectExisting>
				)}

				{map && (
					<MapEditorModal
						mapType={mapType}
						notification={record instanceof Notification ? record : undefined}
						map={map}
						open={isOpen}
						onSave={onSave}
						onClose={onClose}
					/>
				)}
			</Container>
		</Grid>
	)
}
export default observer(MapField)
