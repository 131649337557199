import { Alert, Skeleton } from '@material-ui/lab'
import YAML from 'js-yaml'
import { fromPairs, pick, set } from 'lodash'
import * as React from 'react'
import { getErrorMessage } from '~api/helpers'
import PageHeader from '~components/layout/pages/PageHeader'
import TranslationEditor, { TranslationEditorNode } from '~components/translations/TranslationEditor'
import useStore from '~store/hooks/useStore'

export interface ILangPageProps {}

export const languages = ['nl'] as const

const LangPage = (props: ILangPageProps) => {
	// State
	const [lang, setLang] = React.useState<{ [key: string]: any } | null>(null)
	const [error, setError] = React.useState<string | null>(null)
	const { api } = useStore()

	// Load
	const loadLangs = React.useCallback(async () => {
		// Get em all
		setError(error)
		const promises = languages.map(lang => {
			return api.get(`/admin/lang/${lang}`, {
				params: {
					cacheBust: new Date().getTime(),
				},
				deserialize: false,
			})
		})

		// Error
		try {
			// Wait for all languages
			const result = (await Promise.all(promises)) as unknown as string[]

			// Parse 'em
			const yamls = result.map(yaml => YAML.safeLoad(yaml))

			// Set data
			setLang(fromPairs(languages.map((lang, index) => [lang, yamls[index]])))
		} catch (error) {
			setError(getErrorMessage(error))
		}
	}, [])
	React.useEffect(() => {
		loadLangs()
	}, [])

	// Save
	const handleSubmit = React.useCallback(
		async (dirty: TranslationEditorNode[]) => {
			// Send over the dirty keys
			const data = {
				nodes: dirty.map(node => ({
					path: node.path.join('.'),
					translations: pick(node.translations, languages),
				})),
			}
			await api.call('/admin/lang', 'post', {
				data,
				postJson: true,
				deserialize: false,
			})
			// const changed = { ...lang }

			// console.log(dirty)

			// dirty.forEach(node => [
			// 	Object.keys(node.translations!).map(l => {
			// 		set(changed, `${l}.${node.path.join('.')}`, node.translations![l])
			// 	}),
			// ])

			// // Convert back to YAML
			// const yamls: any = {}
			// Object.keys(changed).forEach(l => {
			// 	yamls[l] = YAML.safeDump(changed[l], {
			// 		indent: 2,
			// 	})
			// })

			// // Save it
			// await api.call('/admin/lang', 'put', {
			// 	data: yamls,
			// 	deserialize: false,
			// })

			// // Apply changed
			// setLang(changed)
			return true
		},
		[lang, api]
	)

	return (
		<React.Fragment>
			<PageHeader title='Website-teksten' isLoading={!lang} />
			{error && <Alert severity={'error'}>{error}</Alert>}
			{lang ? <TranslationEditor onSubmit={handleSubmit} data={lang} /> : <Skeleton height={1230} />}
		</React.Fragment>
	)
}
export default LangPage
