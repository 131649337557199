import { MapData } from '~map-element/index'
import * as React from 'react'
import { Map } from '../models/Map'

export const MapModelContext = React.createContext<Map>(new Map({}))
export const MapModelProvider = MapModelContext.Provider

export function useMapModel(): Map {
	return React.useContext(MapModelContext)
}
