import * as React from 'react'
import styled, { useTheme } from 'styled-components'
import classNames from 'classnames'
import { Layer } from '~map-element/index'
import { observer } from 'mobx-react'
import Icon from '~components/gui/Icon'
import { FontAwesomeIconName } from '~core/enums/icons'
import { throttle, truncate } from 'lodash'
import chroma from 'chroma-js'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const TreeItem = styled.div``
const Children = styled.div`
	padding-left: ${props => props.theme.spacing(1.5)}px;
`

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;
	font-size: 12px;
	border-left: 3px solid ${props => props.theme.palette.primary.main}99;
	color: #ccc;
	user-select: none;

	> * {
	}

	&.alternate {
		border-left-color: ${props => props.theme.palette.secondary.main}99;
	}

	border-bottom: 1px dotted #333;
`
const Title = styled.div``
const Right = styled.div`
	border-left: 1px dotted #444;
	flex: 0;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	padding: 0;
`

const Header = styled.div`
	padding: 12px 12px;
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: center;

	&:hover,
	.hover & {
		cursor: pointer;
		background: #444;
		color: #fff;
	}
	.selected & {
		border-left-color: ${props => props.theme.palette.secondary.main};
		background: #555;
		color: #fff;
	}

	.hidden & {
		opacity: 0.25;
	}
	.fa {
		margin-right: 0.5em;
	}
`

const Button = styled.button`
	border: none;
	background: none;
	padding: 0 8px;
	> i {
		color: #666 !important;
	}
	&:focus {
		outline: none;
	}
	&:hover {
		cursor: pointer;
		background: #444;
		> i {
			color: inherit !important;
		}
	}
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface ILayerMenuItemProps {
	layer: Layer
	onClick: (e: MouseEvent, layer: Layer) => any
}

const LayerMenuItem = observer(({ layer, onClick }: ILayerMenuItemProps) => {
	const clickHandler = React.useCallback(
		(e: MouseEvent) => {
			// Pass it along
			onClick(e, layer)
		},
		[onClick]
	)

	const theme = useTheme()

	// Color
	const color = layer.layerColor
	const c = chroma(color)

	// Hover
	const onMouseEnter = React.useMemo(() => throttle(() => layer.setHover(true), 100, { leading: false }), [layer])
	const onMouseLeave = React.useCallback(
		() => (layer.isHovering ? layer.setHover(false) : onMouseEnter.cancel()),
		[layer.isHovering]
	)

	// Expanded
	const handleToggleExpand = React.useCallback(() => layer.toggleExpanded(!layer.isExpanded), [layer.isExpanded])
	const isExpanded = React.useMemo(() => layer.subLayers && layer.isExpanded, [layer.subLayers, layer.isExpanded])

	// Class
	const className = classNames({
		selected: layer.isSelected,
		hover: layer.isHovering,
		hidden: layer.isHidden,
	})

	return (
		<TreeItem>
			<Row className={className}>
				<Header onClick={clickHandler} onMouseEnter={() => onMouseEnter()} onMouseLeave={() => onMouseLeave()}>
					<Icon fixedWidth name={layer.layerIcon} />
					<Title title={layer.name}>{truncate(layer.name, { length: 35 })}</Title>
				</Header>
				<Right>
					{layer.subLayers && (
						<Button onClick={handleToggleExpand} style={{ color }}>
							<Icon fixedWidth name={layer.isExpanded ? 'folder-open' : 'folder'} />
						</Button>
					)}
					<React.Fragment>
						<Button
							title={layer.isVisible ? `Verberg "${layer.name}"` : `Toon "${layer.name}"`}
							onClick={() => layer.setVisible(!layer.isVisible)}
							style={{ color }}>
							<Icon fixedWidth name={layer.isVisible ? 'eye' : 'eye-slash'} />
						</Button>

						<Button
							title={`Verwijder "${layer.name}"`}
							style={{ color: theme.palette.error.main }}
							onClick={() => layer.delete()}>
							<Icon fixedWidth name={'trash'} />
						</Button>
					</React.Fragment>
				</Right>
			</Row>

			{isExpanded && (
				<Children>
					{layer.subLayers!.map(sub => (
						<LayerMenuItem onClick={onClick} layer={sub} key={sub.$modelId} />
					))}
				</Children>
			)}
		</TreeItem>
	)
})
export default LayerMenuItem
