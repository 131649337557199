import { Provider, observer } from 'mobx-react'
import * as React from 'react'
import { hot } from 'react-hot-loader'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { createRootStore } from '~store/RootStore'
import PageUri from '../core/enums/pages'
import UserForm from './pages/users/UserForm'
import Layout from './layout/Layout'
import DashboardPage from './pages/DashboardPage'
import LoginPage from './pages/LoginPage'
import TravelProductIndexPage from './pages/travel-products/TravelProductIndexPage'
import UserIndexPage from './pages/users/UserIndexPage'
import ProtectedRoute from './routing/ProtectedRoute'
import ResourceRoutes from './routing/ResourceRoutes'
import TravelProductForm from './pages/travel-products/TravelProductForm'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import 'moment/locale/nl'

import LangPage from './pages/lang/LangPage'
import NotificationIndexPage from './pages/notifications/NotificationIndexPage'
import NotificationForm from './pages/notifications/NotificationForm'
import useStore from '~store/hooks/useStore'
import MainSnackbar from './layout/MainSnackbar'
import LineIndexPage from './pages/lines/LineIndexPage'
import LineForm from './pages/lines/LineForm'
import NavigationItemIndexPage from './pages/navigation-items/NavigationItemIndexPage'
import NavigationItemForm from './pages/navigation-items/NavigationItemForm'
import CopyNotificationForm from './pages/notifications/CopyNotificationForm'
import MapIndexPage from './pages/maps/MapIndexPage'
import MapForm from './pages/maps/MapForm'
import PageNotFound from './pages/errors/PageNotFound'
import PlaceIndexPage from './pages/pages/PlaceIndexPage'
import PlaceCategoryIndexPage from './pages/pages/PlaceCategoryIndexPage'
import PlaceCategoryFormPage from './pages/pages/PlaceCategoryFormPage'
import PlaceFormPage from './pages/pages/PlaceFormPage'
import { Helmet } from 'react-helmet'
import { isRRReis } from '~core/config/Config'

export interface IAppProps {}

moment.locale('nl')

const App = observer((props: IAppProps) => {
	const { gui, api } = useStore()
	const { user } = api

	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<Helmet>
				<title>{isRRReis ? 'RRReis' : 'Keolis Reizen'} CMS</title>
			</Helmet>
			<Router>
				<Switch>
					{/* Login */}
					<Route exact path={PageUri.Login}>
						<LoginPage />
					</Route>

					<ProtectedRoute path={'/'}>
						<Layout>
							<Switch>
								<Route exact path={PageUri.Dashboard} component={DashboardPage} />

								{user && (
									<React.Fragment>
										{/* Travel products */}
										{user.hasRole('producten') && (
											<ResourceRoutes
												path={PageUri.TravelProducts}
												Index={TravelProductIndexPage}
												Form={TravelProductForm}
											/>
										)}

										{/* Lang */}
										{user.hasRole('content') && <Route exact path={PageUri.Lang} component={LangPage} />}

										{user.hasRole('meldingen') && (
											<React.Fragment>
												{/* Notifications */}
												<Route
													exact
													path={`${PageUri.Notifications}/:id(\\d+)/kopieren`}
													render={({ match }) => <CopyNotificationForm notificationId={match.params.id} />}
												/>
												<ResourceRoutes
													path={PageUri.Notifications}
													Index={NotificationIndexPage}
													Form={NotificationForm}
												/>

												{/* Maps */}
												<ResourceRoutes path={PageUri.Maps} Index={MapIndexPage} Form={MapForm} />
											</React.Fragment>
										)}

										{/* Lines */}
										{user.hasRole('vervoer') && (
											<ResourceRoutes path={PageUri.Lines} Index={LineIndexPage} Form={LineForm} />
										)}

										{/* Navigations */}
										{user.hasRole('content') && (
											<React.Fragment>
												<ResourceRoutes
													path={PageUri.NavigationItems}
													Index={NavigationItemIndexPage}
													Form={NavigationItemForm}
												/>
												<ResourceRoutes path={PageUri.Places} Index={PlaceIndexPage} Form={PlaceFormPage} />
												<ResourceRoutes
													path={PageUri.PlaceCategories}
													Index={PlaceCategoryIndexPage}
													Form={PlaceCategoryFormPage}
												/>
											</React.Fragment>
										)}

										{/* Users */}
										{user.hasRole('admin') && (
											<ResourceRoutes path={PageUri.Users} Index={UserIndexPage} Form={UserForm} />
										)}
									</React.Fragment>
								)}
								<Route path='*' component={PageNotFound} />
							</Switch>
						</Layout>
					</ProtectedRoute>
				</Switch>
				<MainSnackbar />
			</Router>
		</MuiPickersUtilsProvider>
	)
})

declare const module: any
export default hot(module)(App)
