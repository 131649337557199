import * as React from 'react'
import {
	createStyles,
	makeStyles,
	Theme,
	Dialog,
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	Slide,
	InputBase,
} from '@material-ui/core'
import styled from 'styled-components'
import { TransitionProps } from '@material-ui/core/transitions'

import Config from '~core/config/Config'
import { Map } from '~store/models/Map'
import MapEditor from './MapEditor'
import Icon from '~components/gui/Icon'
import Button from '~components/buttons/Button'
import useStore from '~store/hooks/useStore'
import { draft, getSnapshot } from 'mobx-keystone'
import { MapData } from '~map-element/index'
import html2canvas from 'html2canvas'
import { sleep } from '~utils/sleep'
import $ from 'jquery'
import { canvasToBlob } from '~utils/canvasToBlob'
import FileSaver from 'file-saver'
import { Notification } from '~store/models/Notification'
import slugify from 'slugify'
import getMapCanvas from '~utils/getMapCanvas'
import Loading from '~components/gui/Loading'
import { observer } from 'mobx-react'
import { GuiStoreNotificationSeverity } from '~store/stores/GuiStore'

// ███████╗████████╗██╗   ██╗██╗     ███████╗███████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔════╝
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ███████╗
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ╚════██║
// ███████║   ██║      ██║   ███████╗███████╗███████║
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚══════╝
//

const TopBar = styled(AppBar)`
	position: relative;
`
const Title = styled(Typography)`
	margin-left: 8px;
	flex: 1;
`
const Main = styled.div`
	flex: 1;
	overflow: hidden;
`
// const Loading = styled.div`
// 	position: fixed;
// 	top: 0;
// 	right: 0;
// 	bottom: 0;
// 	left: 0;
// 	background: rgba(0, 0, 0, 0.5);
// 	z-index: 10000;
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	justify-content: center;
// `

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface IMapEditorModalProps {
	open?: boolean
	requireTitle?: boolean
	map: Map
	notification?: Notification
	onClose?: () => any
	onSave?: (map: Map) => any
	confirmLeave?: string | false
	mapType?: 'driver' | 'public'
}

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})
const MapEditorModal = ({
	open = false,
	requireTitle = false,
	mapType,
	notification,
	map,
	confirmLeave = 'Er zijn wijzigingen gemaakt aan de kaart. Weet je zeker dat je deze niet wilt opslaan?',
	onSave,
	onClose,
}: IMapEditorModalProps) => {
	// Resources
	const { api, gui } = useStore()

	// State
	const [isSaving, setIsSaving] = React.useState<boolean>(false)
	const [busyMessage, setBusyMessage] = React.useState<string | null>(null)
	const [isExporting, setIsExporting] = React.useState<boolean>(false)
	const [mapDiv, setMapDiv] = React.useState<HTMLDivElement | null>(null)

	// Ref
	const titleInputRef = React.useRef<HTMLInputElement>()

	// Draft
	const mapDraft = React.useMemo(() => draft(map), [map])

	//  █████╗  ██████╗████████╗██╗ ██████╗ ███╗   ██╗███████╗
	// ██╔══██╗██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
	// ███████║██║        ██║   ██║██║   ██║██╔██╗ ██║███████╗
	// ██╔══██║██║        ██║   ██║██║   ██║██║╚██╗██║╚════██║
	// ██║  ██║╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║███████║
	// ╚═╝  ╚═╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
	//

	const handleClose = React.useCallback(() => {
		// Confirm?
		if (mapDraft.isDirtyByPath(['mapData']) && confirmLeave) {
			if (confirm(confirmLeave)) onClose && onClose()
		} else {
			onClose && onClose()
		}
	}, [onClose, confirmLeave, mapDraft])

	const handleSave = React.useCallback(async () => {
		// Title?
		if (requireTitle && !mapDraft.data.title) {
			gui.showNotification({
				message: 'Vul een titel in voor deze kaart',
				severity: GuiStoreNotificationSeverity.error,
			})
			if (titleInputRef.current) titleInputRef.current.focus()

			return
		}

		setIsSaving(true)
		setBusyMessage('Bezig met genereren van de afbeelding...')
		try {
			// Prepare map for save
			const data = mapDraft.data
			data.prepareForImage()
			data.prepareForSaving()

			// Create image
			if (mapDiv) {
				const canvas = await getMapCanvas(data, mapDiv, msg => setBusyMessage(msg))
				data.setImageByCanvas(canvas)
			}

			// Save it.
			setBusyMessage('Bezig met opslaan van gegevens op server...')
			await api.saveModel(mapDraft, {
				onlyDirty: false,
				omitAttributes: ['mapData'],
				applyAttributesFromServer: ['id', 'public_token'],
			})

			// Saved
			onSave && onSave(mapDraft.data)
		} catch (error) {
			gui.showError(error)
		} finally {
			setIsSaving(false)
			setBusyMessage(null)
		}
	}, [map, mapDraft, onSave, mapDiv, onClose, api, requireTitle, map.title, titleInputRef])

	/**
	 * EXPORT IMAGE
	 */
	const handleExportImage = React.useCallback(async () => {
		if (!mapDiv) return
		setIsSaving(true)
		setIsExporting(true)
		setBusyMessage('Bezig met genereren van de afbeelding...')
		try {
			const canvas = await getMapCanvas(map, mapDiv)

			// Create filename
			const filename =
				notification && notification.title && notification.title.nl
					? `${slugify(notification.title.nl, { lower: true, remove: /[*+~.()'"!:@]/g })}.png`
					: 'nieuwe-melding.png'

			// Blob it.
			const blob = await canvasToBlob(canvas, 'png', 100)
			FileSaver.saveAs(blob!, filename)

			// Invalidate the map
		} catch (error) {
			gui.showError(error)
		} finally {
			setIsSaving(false)
			setIsExporting(false)
			setBusyMessage(null)
		}
	}, [map, mapDiv, notification])

	const modalTitle = React.useMemo(() => {
		// No notification
		if (!notification) {
			if (map.title) return map.title
			return 'Nieuwe kaart'
		}

		// Use notification title
		const noti = notification.title && notification.title.nl ? notification.title.nl : 'Nieuwe melding'
		if (mapType) {
			return `${mapType === 'driver' ? 'Chauffeurkaart voor ' : 'Reizigerskaart voor '} '${noti}'`
		}
		return noti
	}, [notification, map, mapType])

	return (
		<Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} disableEscapeKeyDown>
			<TopBar>
				<Toolbar>
					<IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
						<Icon name={'times'} />
					</IconButton>
					<Title variant='h3'>
						{requireTitle ? (
							<InputBase
								ref={titleInputRef}
								value={mapDraft.data.title || ''}
								onChange={e => mapDraft.data.setAttribute('title', e.target.value)}
								fullWidth
								placeholder={'Vul een titel in'}
							/>
						) : (
							modalTitle
						)}
					</Title>
					<Button loading={isSaving} color='primary' onClick={handleExportImage} style={{ marginRight: 12 }}>
						Exporteren als PNG
					</Button>
					<Button loading={isSaving} color='primary' onClick={handleSave}>
						Opslaan
					</Button>
				</Toolbar>
			</TopBar>
			<Main ref={div => setMapDiv(div)}>{open && <MapEditor map={mapDraft.data} />}</Main>
			{busyMessage && <Loading label={busyMessage} />}
		</Dialog>
	)
}
export default observer(MapEditorModal)
