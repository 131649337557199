import { Model, model, tProp, modelAction, modelFlow, _async, _await, types } from 'mobx-keystone'
import { ValidationRule, ModelValidationConfig } from '~api/model/validateModel'
import { computed } from 'mobx'
import { map } from 'lodash'
import { Translation } from './utility/Translation'
import { ApiModel } from '~api/model/ApiModel'

enum TravelProductType {
	SingleTicket = 'SingleTicket',
	DayTicket = 'DayTicket',
	MonthOrYearPass = 'MonthOrYearPass',
	MonthPass = 'MonthPass',
	YearPass = 'YearPass',
}
export const TravelProductTypes = {
	SingleTicket: 'Kaartje',
	DayTicket: 'Dagkaartje',
	MonthOrYearPass: 'Maand/jaarabonnement',
	MonthPass: 'Maandabonnement',
	YearPass: 'Jaarabonnement',
}

export const Operators = {
	'U-OV': 'U-OV',
	Arriva: 'Arriva',
	Breng: 'Breng',
	Connexxion: 'Connexxion',
	EBS: 'EBS',
	GVB: 'GVB',
	Hermes: 'Hermes',
	HTM: 'HTM',
	Keolis: 'Keolis',
	NS: 'NS',
	Qbuzz: 'Qbuzz',
	RET: 'RET',
	Syntus: 'Syntus',
	Veolia: 'Veolia',
}
export const TravelModes = {
	Bus: 'Bus',
	Buurtbus: 'Buurtbus',
	Nachtbus: 'Nachtbus',
	Tram: 'Tram',
	Subway: 'Subway',
	Train: 'Train',
	Ferry: 'Ferry',
}
export const AgeGroups = {
	Child: 'Child',
	Teenager: 'Teenager',
	Adult: 'Adult',
	Senior: 'Senior',
}

export const PaymentMethods = {
	'ov-chipkaart': 'OV-chipkaart',
	pin: 'PIN',
	ovpay: 'OVpay',
}

const nullableArray = (type = types.string) => tProp(types.maybeNull(types.array(type)))

@model('TravelProduct')
export class TravelProduct extends ApiModel({
	//  █████╗ ████████╗████████╗██████╗ ██╗██████╗ ██╗   ██╗████████╗███████╗███████╗
	// ██╔══██╗╚══██╔══╝╚══██╔══╝██╔══██╗██║██╔══██╗██║   ██║╚══██╔══╝██╔════╝██╔════╝
	// ███████║   ██║      ██║   ██████╔╝██║██████╔╝██║   ██║   ██║   █████╗  ███████╗
	// ██╔══██║   ██║      ██║   ██╔══██╗██║██╔══██╗██║   ██║   ██║   ██╔══╝  ╚════██║
	// ██║  ██║   ██║      ██║   ██║  ██║██║██████╔╝╚██████╔╝   ██║   ███████╗███████║
	// ╚═╝  ╚═╝   ╚═╝      ╚═╝   ╚═╝  ╚═╝╚═╝╚═════╝  ╚═════╝    ╚═╝   ╚══════╝╚══════╝
	//
	id: tProp(types.maybe(types.number)),
	operator_key: tProp(types.string, 'Syntus'),
	slug: tProp(types.string),
	title: tProp(types.model<Translation>(Translation)),
	description: tProp(types.model<Translation>(Translation)),
	payment_methods: tProp(types.array(types.string), () => []),
	type: tProp(types.enum<TravelProductType>(TravelProductType), (): TravelProductType => TravelProductType.MonthPass),

	// White/blacklists
	only_specific_operators: nullableArray(),
	except_specific_operators: nullableArray(),

	only_specific_travel_modes: nullableArray(),
	except_specific_travel_modes: nullableArray(),

	only_specific_line_numbers: nullableArray(),
	except_specific_line_numbers: nullableArray(),
	including_specific_line_numbers: nullableArray(),

	only_specific_concessions: nullableArray(),

	// Zones
	minimum_zone_range: tProp(types.maybeNull(types.number)),
	zone_range: tProp(types.maybeNull(types.number)),
	has_center_zone: tProp(types.boolean),
	valid_in_zones: nullableArray(),
	not_valid_in_zones: nullableArray(),
	allowed_center_zones: nullableArray(),
	hide_zone_range: tProp(types.boolean),

	// Config
	for_ages: nullableArray(),
	disable_price_calculations: tProp(types.boolean),
	fare_discount_percentage: tProp(types.maybeNull(types.number)),
	fare_discount_percentage_morning_peak: tProp(types.maybeNull(types.number)),
	fare_discount_percentage_afternoon_peak: tProp(types.maybeNull(types.number)),
	fare_discount_percentage_outside_peak: tProp(types.maybeNull(types.number)),
	is_valid_during_morning_peak: tProp(types.boolean),
	is_valid_during_afternoon_peak: tProp(types.boolean),

	// Shop
	more_information_url: tProp(types.maybeNull(types.model<Translation>(Translation)), null),
	webshop_product_url: tProp(types.maybeNull(types.model<Translation>(Translation)), null),
	webshop_product_year_url: tProp(types.maybeNull(types.model<Translation>(Translation)), null),
	is_only_available_in_mobile_app: tProp(types.boolean),

	// Price
	price_ticket_full: tProp(types.maybeNull(types.number)),
	price_ticket_reduction: tProp(types.maybeNull(types.number)),
	price_monthly_full: tProp(types.maybeNull(types.number)),
	price_monthly_reduction: tProp(types.maybeNull(types.number)),
	price_yearly_full: tProp(types.maybeNull(types.number)),
	price_yearly_reduction: tProp(types.maybeNull(types.number)),

	// Validity
	valid_from: tProp(types.maybeNull(types.string)),
	valid_until: tProp(types.maybeNull(types.string)),
}) {
	//  ██████╗ ██████╗ ███╗   ███╗██████╗ ██╗   ██╗████████╗███████╗██████╗
	// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██║   ██║╚══██╔══╝██╔════╝██╔══██╗
	// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║   ██║   █████╗  ██║  ██║
	// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║   ██║   ██╔══╝  ██║  ██║
	// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝   ██║   ███████╗██████╔╝
	//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝    ╚═╝   ╚══════╝╚═════╝
	//

	@computed
	get typeTitle(): string {
		return TravelProductTypes[this.type]
	}

	@computed
	get hasPriceMonthly(): boolean {
		return this.type === 'MonthPass' || this.type === 'MonthOrYearPass'
	}
	@computed
	get hasPriceYearly(): boolean {
		return this.type === 'YearPass' || this.type === 'MonthOrYearPass'
	}
	@computed
	get hasPriceTicket(): boolean {
		return this.type === 'SingleTicket' || this.type === 'DayTicket'
	}

	@computed
	get discountDescription(): string | null {
		const others: any = {}
		if (
			this.fare_discount_percentage_morning_peak &&
			(!this.fare_discount_percentage_afternoon_peak ||
				this.fare_discount_percentage_afternoon_peak === this.fare_discount_percentage_morning_peak)
		) {
			others['spits'] = this.fare_discount_percentage_morning_peak
		} else if (this.fare_discount_percentage_morning_peak) {
			others['ochtend'] = this.fare_discount_percentage_morning_peak
		}
		if (
			this.fare_discount_percentage_afternoon_peak &&
			(!this.fare_discount_percentage_morning_peak ||
				this.fare_discount_percentage_afternoon_peak !== this.fare_discount_percentage_morning_peak)
		) {
			others['avond'] = this.fare_discount_percentage_afternoon_peak
		}
		if (this.fare_discount_percentage_outside_peak) others['dal'] = this.fare_discount_percentage_outside_peak

		return Object.keys(others).length === 0 ? null : map(others, (perc, key) => `${key} ${perc}%`).join(' / ')
	}

	//  ██████╗ ██████╗ ███╗   ██╗███████╗██╗ ██████╗ ██╗   ██╗██████╗  █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
	// ██╔════╝██╔═══██╗████╗  ██║██╔════╝██║██╔════╝ ██║   ██║██╔══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
	// ██║     ██║   ██║██╔██╗ ██║█████╗  ██║██║  ███╗██║   ██║██████╔╝███████║   ██║   ██║██║   ██║██╔██╗ ██║
	// ██║     ██║   ██║██║╚██╗██║██╔══╝  ██║██║   ██║██║   ██║██╔══██╗██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
	// ╚██████╗╚██████╔╝██║ ╚████║██║     ██║╚██████╔╝╚██████╔╝██║  ██║██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
	//  ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝╚═╝     ╚═╝ ╚═════╝  ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
	//

	static validation(record?: TravelProduct): ModelValidationConfig {
		return {
			required: ['title', 'description', 'type'],
		}
	}

	static getDefaultValues() {
		return {
			operator_key: 'Syntus',
		}
	}
}
