import { Paper, Typography, Dialog } from '@material-ui/core'
import { observer } from 'mobx-react'
import * as React from 'react'
import Draggable from 'react-draggable'
import styled from 'styled-components'
import { Map } from '~store/models/Map'
import Icon from '~components/gui/Icon'
import { FontAwesomeIconName } from '~core/enums/icons'
import { Layer, EditableProperties, EditableProperty } from '~map-element/index'
import ConfirmDialog from '~components/dialogs/ConfirmDialog'
import LayerProperty from './LayerProperty'
import { uniq, flatten } from 'lodash'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

export const LayerPropertiesWidth = 320

const Container = styled(Paper)`
	background: #333;
	overflow: hidden;
	width: ${LayerPropertiesWidth}px;
	color: white;
	position: absolute;
	right: 16px;
	bottom: 16px;
	z-index: 10900;
`
const Header = styled.div`
	background: ${props => props.theme.palette.primary.main};
	display: flex;
	> h3 {
		flex-grow: 1;
	}
	align-items: center;
	padding: 8px 12px;
	font-size: 13px;
	i:first-of-type {
		font-size: 16px;
		margin-right: 0.5em;
	}
`
const Main = styled.div`
	font-size: 12px;
	max-height: 600px;
	overflow-y: auto;
	p {
		color: #999;
		padding: 12px;
		margin: 0;
	}
`

const HeaderIconButton = styled.span`
	cursor: pointer;
	margin-left: 4px;
	&:hover {
		color: #333;
	}
`

const DragHandle = styled.span`
	cursor: move;
	padding: 8px 1px 8px 8px;
	&:hover {
		color: #333;
	}
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//
export interface ILayerPropertiesProps {
	layers: Layer[]
	map: Map
}

type EditablePropertyLayers = {
	layers: Layer[]
	property: EditableProperty
}

const LayerProperties = ({ layers, map }: ILayerPropertiesProps) => {
	// Icon / title
	const icon = React.useMemo<FontAwesomeIconName>(() => {
		if (layers.length === 0) return 'question'
		if (layers.length === 1) {
			return (layers[0].icon || layers[0].layerConfig.icon) as FontAwesomeIconName
		}
		return 'layer-group'
	}, [layers])
	const title = React.useMemo<string>(() => {
		if (layers.length === 1) return layers[0].name
		return `${layers.length} lagen`
	}, [layers])

	// Properties
	const properties = React.useMemo<EditablePropertyLayers[]>(() => {
		// Always the visibility
		const props: EditablePropertyLayers[] = [{ property: EditableProperties.IsVisible, layers }]

		// Name, only when 1 is selected
		if (layers.length === 1) props.push({ property: EditableProperties.Name, layers })

		// Combine all config props
		const configs = uniq(layers.map(l => l.layerConfig))
		const configProps = uniq(flatten(configs.map(config => config.editableProperties)))
			// Filter out 'name'-like props and editing for multiple layers
			.filter(p => {
				if (layers.length === 1) return true
				return p && p.type !== 'string' && p.type !== 'editPolyline' && p.type !== 'splitPolyline'
			})

		// No find layers that have those types
		return [
			...props,
			...configProps.map(prop => {
				return {
					property: prop!,
					layers: layers.filter(layer => {
						return layer.layerConfig.editableProperties && layer.layerConfig.editableProperties.indexOf(prop!) > -1
					}),
				}
			}),
		]
	}, [layers])

	// Delete
	const [showConfirmDelete, setShowConfirmDelete] = React.useState<boolean>(false)
	const handleDelete = React.useCallback(() => {
		// Delete the layers
		layers.forEach(layer => layer.delete())

		// Done.
		setShowConfirmDelete(false)
	}, [layers, map])

	// Is editing / splitting
	const isEditing = !!layers.find(layer => layer.isEditing)
	const isSplitting = !!layers.find(layer => layer.isSplitting)

	if (layers.length === 0) return null

	return (
		<React.Fragment>
			<Draggable handle={'.drag-handle'}>
				<Container>
					<Header>
						<Icon name={icon} />
						<Typography variant='h3'>{title}</Typography>

						<DragHandle className={'drag-handle'} title={'Verplaats scherm'}>
							<Icon name={'arrows-alt'} />
						</DragHandle>

						<HeaderIconButton onClick={() => setShowConfirmDelete(true)}>
							<Icon name={'trash'} />
						</HeaderIconButton>
						<HeaderIconButton onClick={() => map.mapData.selectLayer(null)}>
							<Icon name={'times'} />
						</HeaderIconButton>
					</Header>
					<Main>
						{properties.map(prop => (
							<LayerProperty
								isSplitting={isSplitting}
								isEditing={isEditing}
								key={`${prop.property.name}${prop.property.label}`}
								property={prop.property}
								layers={prop.layers}
							/>
						))}
					</Main>
				</Container>
			</Draggable>
			<Dialog open={showConfirmDelete} onClose={() => setShowConfirmDelete(false)}>
				<ConfirmDialog
					content={`Weet je zeker dat je deze ${layers.length === 1 ? 'laag' : 'lagen'} wilt verwijderen?`}
					onConfirm={handleDelete}
					onCancel={() => setShowConfirmDelete(false)}
				/>
			</Dialog>
		</React.Fragment>
	)
}
export default observer(LayerProperties)
